<template>
  <div v-if="display" id="loader"></div>
</template>

<script>
export default {
  data() {
      return {
          loader : {},
          display: false
      }
  },
  mounted() {
      this.loader = document.getElementById("loader");
  },
  watch:{
      display(){
        this.loadNow(1);
      }
  },
  methods: {
    loadNow(opacity) {
      if (opacity <= 0) {
        this.displayContent();
      } else {
        this.loader.style.opacity = opacity;
        window.setTimeout(function () {
          this.loadNow(opacity - 0.05);
        }, 50);
      }
    },
    displayContent() {
      this.loader.style.display = "none";
    }
  },
};
</script>

<style>
</style>